<template>
  <div>
    <v-form ref="form">
      <v-text-field
        v-model="organization.name"
        label="Name"
        required
        :rules="[v => !!v || 'Name is required']"
      ></v-text-field>

      <v-autocomplete
        :items="countries"
        item-text="title"
        item-value="_id"
        v-model="organization.country"
        autocomplete
        label="Country"
        :rules="[v => !!v || 'Country is required']"
      ></v-autocomplete>

      <v-text-field
        v-model="organization.email"
        label="E-mail"
        required
        :rules="emailRules"
      ></v-text-field>

      <v-text-field
        v-model="organization.contact"
        label="Contact"
        required
        :rules="mobileNumberRules"
      ></v-text-field>

      <v-autocomplete
        :items="colors"
        item-text="name"
        item-value="hex"
        v-model="organization.themeColor"
        persistent-hint
        hint="Leave empty for default theme color"
        label="Theme Color"
      ></v-autocomplete>

      <v-select
        label="Status"
        :items="status"
        v-model="organization.status"
        item-text="name"
        item-value="key"
        required
        :rules="[v => !!v || 'Status is required']"
      >
      </v-select>
    </v-form>

    <v-btn color="primary mr-2" @click="$router.push({ name: 'OrganizationIndex' })">Back</v-btn>
    <v-btn color="primary mr-2" @click="createOrganization" v-if="type == 'CREATE'">Create</v-btn>
    <v-btn color="primary mr-2" @click="updateOrganization" v-if="type == 'UPDATE'">Update</v-btn>
  </div>
</template>

<script>
import RestResource from "@/services/dataServiceAdmin.js";
const service = new RestResource();

export default {
  name: "organization",
  props: {
    type: String,
    organization: Object
  },
  data() {
    return {
      status: [ { key: "A", name: "Active" }, { key: "I", name: "Invalid" } ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      mobileNumberRules: [ value => !!value || 'Mobile number required.' ],
      colors: [],
      countries: []
    };
  },

  mounted() {
    this.fetchCountries();
    this.fetchColorCodes();
  },

  methods: {
    async createOrganization() {
      if (this.$refs.form.validate()) {
        this.$setLoader();
        service.createOrganization(this.organization).then(() => {
          this.$disableLoader();
          this.$router.push({ name: "OrganizationIndex" })
          alert("Successfully created organization")
        }).catch(e => {
          this.$disableLoader();
          alert(`Failed to create organization ${e}`)
        });
      }
    },

    async updateOrganization() {
      if (this.$refs.form.validate()) {
        this.$setLoader();
        service.updateOrganization(this.organization).then(() => {
          this.$disableLoader();
          alert("Successfully update organization")
        }).catch(e => {
          this.$disableLoader();
          alert(`Failed to update organization ${e}`)
        });
      }
    },

    fetchCountries() {
      service.getConfig("countries").then(r => {
        this.countries = r.data;
      });
    },

    fetchColorCodes() {
      service.getConfig("colorCodes").then(r => {
        this.colors = r.data;
      });
    }
  }
};
</script>
