<template>
  <v-card>
    <v-card-title>Edit Organization</v-card-title>
    <v-container>
      <OrganizationForm 
        type="UPDATE"
        :organization="organization"
      />
    </v-container>
  </v-card>
</template>

<script>
import OrganizationForm from "./form"
import RestResource from "@/services/dataServiceAdmin.js";

const service = new RestResource();

export default {
  components: {
    OrganizationForm
  },
  data() {
    return {
      organization: {}
    }
  },
  mounted() {
    this.$setLoader()

    let data = {
      _id: this.$route.params.id
    }

    service.fetchOrganizations(data).then(r => {
      this.$disableLoader()

      this.organization = r.data.organizations[0]
    })
  }
};
</script>
